/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import ImageSuccess from '../../images/svg/bg_graph.svg';
import './styles.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const image = new Image();
image.src = ImageSuccess

const plugin = [{
  id: 'customCanvasBackgroundImage',
  beforeDraw: (chart) => {
    if (image.complete) {
      const ctx = chart.ctx;
      const {top, left, width, height} = chart.chartArea;
      ctx.drawImage(image, left, top, width, height);
    } else {
      image.onload = () => chart.draw();
    }
  }
}]

const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: false,
      min: 0,
      max: 100,
      ticks: {
        stepSize: 20,
      }
    }
  },
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      formatter: (value) => `${Math.round(value)}%`,
      font: {
        family: "'Fira Sans', sans-serif",
        size: 16
      }
    },
    legend: {
      display : false
    },
    title: {
      display: false,
    },
  },
};

const dataBody = {
  labels: [],
  datasets: [],
};

function BarChart(props) {
  const [data, setData] = useState(dataBody);
  useEffect(() => {
    setData(props.data);
  }, [props.data])
  
  return (
    <div className="bg-chartBar">
      <Bar data={data} options={options} plugins={plugin} />
    </div>
  )
}

export default BarChart