/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import axios from '../../services/useAxios';
import useQuizData from '../../services/useQuizData';
import BarChart from '../../components/BarChart';
import './styles.css';

function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [totalGroupUsers, setTotalGroupUsers] = useState(0);
  const [totalEventUsers, setTotalEventUsers] = useState(0);
  const [totalGroupAverage, setTotalGroupAverage] = useState({});
  const [totalEventAverage, setTotalEventAverage] = useState({});
  const [showVisor, setShowVisor] = useState(true);

  const { quizData } = useQuizData();

  useEffect(() => {
    getDataGraph();

    const intervalId = setInterval(getDataGraph, 10000);

    if (props.show && props.show === 'false') {
      setShowVisor(false)
    }

    return () => {
      clearInterval(intervalId);
    }
  }, [])

  const getDataGraph = async () => {
    const quizDataBody = await quizData();

    const dataBody = {
      ...quizDataBody
    }

    try {
      const response = await axios.post('/dashboard/barGraph', dataBody)
      const { data: dataResponse } = response;
      const { data } = dataResponse;
      // setTotalUsers(data.totalUsers);
      setTotalEventUsers(data.totalEventUsers);
      setTotalGroupUsers(data.totalGroupUsers);
      setTotalEventAverage(data.totalEventAverage);
      setTotalGroupAverage(data.totalGroupAverage);
      setLoading(false)
    } catch (err) {
      console.log('🚀 ~ file: index.jsx:116 ~ useEffect ~ err:', err);
    }
  }

  return (
    <main className="container m-auto">
      <div className="w-full m-auto md:w-9/12">
        {
          loading ?
            <div className="flex h-screen justify-center items-center">
              <h1>Cargando</h1>
            </div>
          : <div className="flex justify-center items-center flex-col px-5 py-5">
              {
                showVisor && (
                  <div className="w-full py-5">
                    <div className="flex justify-between items-center">
                      <h3 className="text-primary">
                        <span className="font-bold uppercase text-md">Reporte</span>
                        {/* <span className="text-sm">/ {totalGroupUsers} participante{totalGroupUsers === 1 ? '' : 's'}</span> */}
                      </h3>
                    </div>
                  </div>
                )
              }
              <div className="w-full md:mb-4 md:grid md:grid-cols-2 md:gap-4">
                <div className="col-span-1 mb-4 md:mb-0 shadow-lg bg-white rounded-xl w-full p-5">
                  <div className="mb-10">
                    <h3 className="font-bold text-gray">Promedio x sesión <span className="text-sm">({totalGroupUsers} participante{totalGroupUsers === 1 ? '' : 's'})</span></h3>
                  </div>
                  <BarChart data={totalGroupAverage} />
                </div>
                <div className="col-span-1 mb-4 md:mb-0 shadow-lg bg-white rounded-xl w-full p-5">
                  <div className="mb-10">
                    <h3 className="font-bold text-gray">Promedio general <span className="text-sm">({totalEventUsers} participante{totalEventUsers === 1 ? '' : 's'})</span></h3>
                  </div>
                  <BarChart data={totalEventAverage} />
                </div>
              </div>
            </div>
        }
      </div>
    </main>
  )
}

export default Dashboard;