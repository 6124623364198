/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useQuizData from '../../services/useQuizData';
import axios from '../../services/useAxios';
import useLocalStorage from '../../services/useLocalStorage';

function QuizRedirect() {
  const navigate = useNavigate();
  const { quizData } = useQuizData();
  const { getLocalStorage, removeLocalStorage } = useLocalStorage();
  const [message, setMessage] = useState(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const groupParam = queryParams.get('group');

  useEffect(() => {
    getSurveyUrl();
  }, []);

  const getSurveyUrl = async () => {
    const quizDataBody = await quizData();
    // console.log('🚀 ~ file: index.jsx:20 ~ getSurveyUrl ~ quizDataBody:', quizDataBody);
    const client = getLocalStorage('client');

    const { businessName, event: eventId } = quizDataBody;

    try {
      const response = await axios.get('/getSurveyUrl', { params: { company: businessName, event: eventId || 1, keyGroup: groupParam || 1 } })
      const { data: dataResponse } = response;
      const { data } = dataResponse;

      if (client) {
        if (+client.group !== +data.groupId) {
          removeLocalStorage('client');
          removeLocalStorage('quiz');
          removeLocalStorage('questions');
        }
      }

      return navigate(data.url);
    } catch (err) {
      const { data } = err.response;
      const { message } = data;
      setMessage(message);
    }
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="text-center w-2/3">
        <h1 className="text-2xl">{message || 'Cargando...'}</h1>
      </div>
    </div>
  )
}

export default QuizRedirect